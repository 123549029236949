import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { graphql } from "gatsby"
import React from "react"

import CibtacLogo from "../components/cibtacLogo"
import CourseSideBar from "../components/courseSideBar"
import ItecLogo from "../components/itecLogo"
import Layout from "../components/layout"
import SEO from "../components/seo"

interface Props {
  content: string
  description: string
  title: string
  level: string
  itec: boolean
  rqf: boolean
  nqfLevel: string
  vtctLevel: string
  price: string
  newCourse: boolean
  duration: string
  times: Array<string>
  cibtac: boolean
  itecUrl: string
  helmet?: JSX.Element
}

export const CourseTemplate = ({
  content,
  title,
  itec,
  cibtac,
  level,
  helmet,
  itecUrl,
  rqf,
  nqfLevel,
  price,
  newCourse,
  vtctLevel,
  duration,
  times,
}: Props) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <>
      {helmet}
      <div
        style={{
          backgroundColor: "#FAF9FD",
          padding: "60px 32px 40px",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontSize: "2.5rem" }}>{title}</h1>
      </div>
      <Container>
        <Grid
          container
          spacing={isTablet ? 3 : 8}
          style={{ marginBottom: "60px", marginTop: "40px" }}
        >
          <Grid item xs={12} sm={4}>
            <CourseSideBar />
          </Grid>
          <Grid item xs={12} sm={8}>
            <div>
              {itec || cibtac ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "12px",
                    height: "75px",
                  }}
                >
                  {itec && <ItecLogo maxWidth={200} />}
                  {cibtac && <CibtacLogo maxWidth={75} />}
                  {newCourse && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#7f8fa6",
                        height: "28px",
                        borderRadius: "4px",
                      }}
                    >
                      <p
                        style={{
                          padding: "2px 4px",
                          color: "white",
                          margin: 0,
                        }}
                      >
                        NEW
                      </p>
                    </div>
                  )}
                </div>
              ) : null}
              <h3>
                Level {level}
                {rqf ? " (RQF) " : ""}
                {nqfLevel ? ` NQF IE Level ${nqfLevel}` : ""}
                {vtctLevel ? ` VTCT Level ${vtctLevel}` : ""}
              </h3>
              {!!price && (
                <h4>
                  <b>Cost:</b> €{price}
                </h4>
              )}
              {!!duration && <h4>{duration}</h4>}
              {!!times && (
                <h4>
                  <b>Timetable: </b>
                  {times.join(", ")}
                </h4>
              )}
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <a
                href={itecUrl}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                rel="noopener"
                style={{ display: "flex" }}
              >
                <b>Find out more</b>
                <ArrowForwardIcon />
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

interface CourseProps {
  data: {
    markdownRemark: {
      id: string
      html: string
      fields: {
        slug: string
      }
      frontmatter: {
        [key: string]: any
      }
    }
  }
}

const Course = ({ data }: CourseProps) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <CourseTemplate
        content={post.html}
        description={post.frontmatter.description}
        itec={post.frontmatter.itec}
        cibtac={post.frontmatter.cibtac}
        level={post.frontmatter.level}
        itecUrl={post.frontmatter.itecUrl}
        newCourse={post.frontmatter.newCourse}
        rqf={post.frontmatter.rqf}
        nqfLevel={post.frontmatter.nqfLevel}
        price={post.frontmatter.price}
        duration={post.frontmatter.duration}
        times={post.frontmatter.times}
        vtctLevel={post.frontmatter.vtctLevel}
        helmet={
          <SEO
            title={`${post.frontmatter.title}`}
            description={`${post.frontmatter.description}`}
          >
            <script type="application/ld+json">
              {`{
                "@context": "https://schema.org",
                "@type": "Course",
                "name": "${post.frontmatter.title}",
                "description": "${post.frontmatter.description}",
                "provider": {
                  "@type": "EducationalOrganization",
                  "name": "The Institute of Beauty",
                  "sameAs": "${post.frontmatter.itecUrl}"
                }
              }`}
            </script>
            <script type="application/ld+json">
              {`{
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://theinstituteofbeauty.com/"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Courses",
                  "item": "https://theinstituteofbeauty.com/courses"
                },{
                  "@type": "ListItem",
                  "position": 3,
                  "name": "${post.frontmatter.title}",
                  "item": "https://theinstituteofbeauty.com${post.fields.slug}"
                }]
              }`}
            </script>
          </SEO>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

export default Course

export const pageQuery = graphql`
  query CourseByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        level
        itec
        cibtac
        itecUrl
        rqf
        nqfLevel
        price
        newCourse
        duration
        times
        vtctLevel
      }
    }
  }
`
